import React from "react"
import useSound from "use-sound"

// Modal
import { Modal } from "react-responsive-modal"
import "react-responsive-modal/styles.css"
import "./modal.scss"

// Component
import Item from "./Item"
import Confirmation from "./Confirmation"
import Success from "./Success"
import Info from "./Info"
import History from "./History"
import Pack from "./Pack"
import Error from "./Error"

const MODAL_LIST = {
	Item,
	Confirmation,
	Success,
	Info,
	History,
	Pack,
	Error,
}
const cdn = process.env.REACT_APP_CDN_URL

const ModalCont = (props) => {
	const { toggle_popup } = props.actionsMain
	const { popup_type } = props.main
	const Content = MODAL_LIST[popup_type]
	const modalTitle = () => {
		let title
		switch (popup_type) {
			case "History":
				title = "History"
				break
			case "Success":
				title = "Congratulation"
				break
			case "Info":
				title = "Details"
				break
			case "Item":
				title = "Item Details"
				break
			case "Pack":
				title = "Pack Details"
				break
			default:
				break
		}
		return title
	}

	// SFX
	const [playOpen] = useSound(`${cdn}sfx/open.mp3`)

	const closeModal = () => {
		playOpen()
		toggle_popup(false)
	}

	return (
		<Modal
			open={popup_type}
			onClose={() => closeModal()}
			closeOnOverlayClick={false}
			showCloseIcon={false}
			closeOnEsc={false}
			center
			classNames={{
				overlay: `customOverlay`,
				modal: "customModal",
			}}
		>
			<div className="header">
				<span>{modalTitle()}</span>
				<img src={`${cdn}btn_close.png`} alt="" onClick={() => closeModal()} />
			</div>
			{Content ? <Content {...props} /> : null}
		</Modal>
	)
}

export default ModalCont
