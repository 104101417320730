import React, { useEffect } from "react"
import styled from "styled-components"
import useSound from "use-sound"
import { format, parseISO } from "date-fns"

const cdn = process.env.REACT_APP_CDN_URL

const History = (props) => {
	const { history } = props.main

	// SFX
	const [playOpen] = useSound(`${cdn}sfx/open.mp3`)
	useEffect(() => {
		playOpen()
	})

	return (
		<Wrapper>
			<div className="history-header">
				<span>Date/Time</span>
				<span>Reward Claimed</span>
			</div>
			<div className="history-body">
				{history.map?.((data, index) => (
					<div key={index}>
						<span>{format(parseISO(data.created_at), "d MMM HH:mm")}</span>
						<span>{data.item_name}</span>
					</div>
				))}
			</div>
		</Wrapper>
	)
}

export default History

const Wrapper = styled.div`
	height: 88%;
	color: #fff;
	text-align: center;
	padding: 3% 10%;

	.history-header {
		width: 100%;
		font-family: RobotoBlack;
		font-size: 0.23rem;
		border-bottom: 0.05rem solid #fff;
		height: 10%;

		> span {
			display: inline-block;

			&:first-child {
				width: 35%;
			}
			&:last-child {
				width: 65%;
			}
		}
	}

	.history-body {
		width: 100%;
		height: 90%;
		font-family: RobotoMedium;
		overflow-y: scroll;

		> div {
			border-bottom: 0.02rem solid #fff;
			height: 15%;
			display: flex;
			align-items: center;
			font-size: 0.23rem;

			> span {
				vertical-align: middle;
				display: inline-block;

				&:first-child {
					width: 35%;
				}
				&:last-child {
					width: 65%;
				}
			}
		}
	}
`
