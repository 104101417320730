import React, { useState, useEffect } from "react"
import styled from "styled-components"
import useSound from "use-sound"

const cdn = process.env.REACT_APP_CDN_URL

const Pack = (props) => {
	const [slider, setSlider] = useState(1)
	const { selected } = props.main
	const { toggle_popup, put_data, post_purchase } = props.actionsMain

	// SFX
	const [playOpen] = useSound(`${cdn}sfx/open.mp3`)
	useEffect(() => {
		playOpen()
	})

	const confirm = () => {
		put_data("amount", slider)
		if (JSON.parse(window.localStorage.getItem("dontshow"))) {
			post_purchase()
		} else {
			toggle_popup("Confirmation")
		}
	}

	const packTitle = (name) => {
		return selected.item_name?.toLowerCase()?.includes(name)
	}
  
	return (
		<Wrapper>
			<span>
				Open and get 1{" "}
				{packTitle("unowned")
					? "unowned"
					: packTitle("selector")
					? "selector"
					: packTitle("random")
					? "random"
					: ""}{" "}
				item
			</span>
			<div className="pack">
				<div>
					{selected.pack_image?.map((data, index) => (
						<Card
							key={index}
							background={data.image_card}
							skinTag={data.image_tag}
						>
							<div className="details">
								<span className="name">{data.item_name}</span>
							</div>
						</Card>
					))}
				</div>
				<hr />
			</div>
			<div className="qty-price">
				<span className="qty">
					Quantity: <span>{slider}</span>
				</span>
				<div className="price">
					<span>{selected.final_price * slider}</span>
					<img src={`${cdn}voucher.png`} alt="" />
				</div>
			</div>
			<div className="actions">
				<div className="slider-btn btn-minus">
					<img
						src={`${cdn}ico_min.png`}
						alt=""
						onClick={() => (slider > 1 ? setSlider(slider - 1) : null)}
					/>
				</div>
				<input
					type="range"
					min="1"
					max={selected.limit}
					value={slider}
					onChange={(e) => setSlider(e.target.value)}
					id="sliderlimit"
				/>
				<div className="slider-btn btn-plus">
					<img
						src={`${cdn}ico_plus.png`}
						alt=""
						onClick={() =>
							slider < selected.limit ? setSlider(slider + 1) : null
						}
					/>
				</div>
			</div>
			<div className="btn-purchase" onClick={() => confirm()}>
				<span>Purchase</span>
			</div>
		</Wrapper>
	)
}

export default Pack

const Wrapper = styled.div`
	height: 88%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 2%;

	> span {
		color: #fff;
		font-family: RobotoMedium;
		font-size: 0.2rem;
	}

	.pack {
		margin-top: 3%;
		height: 40%;
		width: 70%;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		> div {
			height: 98%;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 3%;
			overflow-x: scroll;
			flex-wrap: wrap;
			flex-direction: column;
		}

		hr {
			margin: 0;
			height: 0.01rem;
			background: linear-gradient(
				to right,
				rgba(255, 255, 255, 0),
				rgba(255, 255, 255, 1),
				rgba(255, 255, 255, 0)
			);
			opacity: 0.5;
			border: none;
		}
	}

	.qty-price {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 5%;

		.qty {
			font-family: RobotoMedium;
			color: #a69672;
			font-size: 0.2rem;

			span {
				color: #fff;
			}
		}

		.price {
			color: #fff;
			font-family: RobotoBlack;
			font-size: 0.23rem;
			display: flex;
			gap: 7%;

			img {
				max-height: 0.26rem;
				vertical-align: middle;
			}
		}
	}
  .slider-section {
    height: 12%;
    display: flex;
    align-items: center;
    
  }

	.actions {
		height: 12%;
		width: 75%;
		padding: 1.5% 0;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 4%;
		background: url(${cdn}limit_bg.png) center/100% 100% no-repeat;

		.slider-btn {
			> img {
				max-height: 100%;
				max-width: 100%;
			}
      flex-basis: 15%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
		}

		> input {
			width: 50%;
			height: 20%;
			background: url(${cdn}slider_bg.png) center/100% 100% no-repeat;
			-webkit-appearance: none;
			appearance: none;
			outline: none;

			&::-webkit-slider-thumb {
				-webkit-appearance: none;
				appearance: none;
				background: url(${cdn}slider_thumb.png) center/contain no-repeat;
				width: 0.23rem;
				height: 0.23rem;
			}
		}
	}

	.btn-purchase {
		background: url(${cdn}btn-yes.png) center/contain no-repeat;
		padding: 1.5% 7%;
		cursor: pointer;
		color: #fff;
    display: flex;

		span {
			font-size: 0.23rem;
			font-family: RobotoMedium;
		}
	}
`
const Card = styled.div`
	background: ${(props) =>
			props.skinTag ? `url(${props.skinTag}) top right/40% no-repeat, ` : ""}
		url(${(props) => props.background}) center/contain no-repeat;
	width: 20.5%;
	height: 100%;
	position: relative;

	.details {
		position: absolute;
		bottom: 3%;
		text-align: center;
		width: 100%;

		.name {
			font-family: RobotoMedium;
			color: #fff;
			font-size: 0.13rem;
			text-shadow: 0.01rem 0.01rem #000000;
			display: block;
			padding: 0 5%;
		}
	}
`
