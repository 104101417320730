import React, { useEffect } from "react"
import styled from "styled-components"
import useSound from "use-sound"

const cdn = process.env.REACT_APP_CDN_URL

const Info = (props) => {
	const { lang_json } = props.main
	// SFX
	const [playOpen] = useSound(`${cdn}sfx/open.mp3`)
	useEffect(() => {
		playOpen()
	})

	return <Wrapper dangerouslySetInnerHTML={{ __html: lang_json.event_info }} />
}

export default Info

const Wrapper = styled.div`
	height: 88%;
	padding: 4% 0;

	ol {
		height: 100%;
		overflow-y: scroll;
		color: #fff;
		font-family: RobotoMedium;
		font-size: 0.23rem;
		list-style: decimal;
		padding-inline-start: 10%;
		padding-inline-end: 5%;

		li {
			a {
				color: #fff8a9;
				outline: 0;

				&:focus {
					outline: 0;
				}
			}
			&:not(:last-child) {
				margin-bottom: 3%;
			}
		}
	}
`
