import React, { useState, useEffect } from "react"
import styled from "styled-components"
import useSound from "use-sound"

const cdn = process.env.REACT_APP_CDN_URL

const Item = (props) => {
	const [slider, setSlider] = useState(1)
	const { selected } = props.main
	const { toggle_popup, put_data, post_purchase } = props.actionsMain

	// SFX
	const [playOpen] = useSound(`${cdn}sfx/open.mp3`)
	useEffect(() => {
		playOpen()
	})

	const confirm = () => {
		put_data("amount", slider)
		if (JSON.parse(window.localStorage.getItem("dontshow"))) {
			post_purchase()
		} else {
			toggle_popup("Confirmation")
		}
	}

	return (
		<Wrapper>
			<div className="details">
				<div className="image">
					<img src={selected.image_icon} alt="" />
				</div>
				<div className="description">
					<span>{selected.item_name}</span>
					<span>{selected.description}</span>
				</div>
			</div>

			<div className="limit-wrapper">
				<span>
					Quantity: <span>{slider}</span>
				</span>
				<div className="actions">
					<div className="slider-btn btn-minus">
						<img
							src={`${cdn}ico_min.png`}
							alt=""
							onClick={() => (slider > 1 ? setSlider(slider - 1) : null)}
						/>
					</div>
					<input
						type="range"
						min="1"
						max={selected.limit}
						value={slider}
						onChange={(e) => setSlider(e.target.value)}
						id="sliderlimit"
					/>
					<div className="slider-btn btn-plus">
						<img
							src={`${cdn}ico_plus.png`}
							alt=""
							onClick={() =>
								slider < selected.limit ? setSlider(slider + 1) : null
							}
						/>
					</div>
				</div>
			</div>

			<hr />

			<div className="price">
				<span>{selected.final_price * slider}</span>
				<img src={`${cdn}voucher.png`} alt="" />
			</div>

			<div className="btn-purchase" onClick={() => confirm()}>
				<span>Purchase</span>
			</div>
		</Wrapper>
	)
}

export default Item

const Wrapper = styled.div`
	height: 88%;
	display: flex;
	flex-direction: column;
	color: #fff;
	padding: 0 11%;
	position: relative;
	align-items: center;
	justify-content: center;
	gap: 2.5%;

	.details {
		display: flex;
		height: 25%;
		align-items: center;
		gap: 5%;
		padding: 0 1%;

		.image {
			background: url(${cdn}icon.png) center/100% 100% no-repeat;
			display: grid;
			place-items: center;
			width: 0.92rem;
			height: 0.86rem;

			> img {
				max-height: 75%;
				max-width: 75%;
				vertical-align: middle;
			}
		}

		.description {
			height: 100%;
			width: 70%;
			display: flex;
			flex-direction: column;
			justify-content: space-evenly;

			> span {
				&:first-child {
					font-family: RobotoBlack;
					font-size: 0.3rem;
				}
				&:last-child {
					font-family: RobotoMedium;
					font-size: 0.17rem;
				}
			}
		}
	}

	.limit-wrapper {
		height: 25%;
		width: 100%;
		text-align: center;
		background: url(${cdn}limit_item_bg.png) center/100% 100% no-repeat;
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 8%;

		> span {
			font-size: 0.23rem;
			font-family: RobotoMedium;
			color: #a69672;

			span {
				color: #fff;
			}
		}

		.actions {
			height: 40%;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 4%;

			.slider-btn {
				height: 100%;
				width: 7.7%;
        display: flex;

				> img {
					max-height: 100%;
					max-width: 100%;
				}
			}

			> input {
				width: 60%;
				height: 20%;
				background: url(${cdn}slider_bg.png) center/100% 100% no-repeat;
				-webkit-appearance: none;
				appearance: none;
				outline: none;

				&::-webkit-slider-thumb {
					-webkit-appearance: none;
					appearance: none;
					background: url(${cdn}slider_thumb.png) center/contain no-repeat;
					width: 0.3rem;
					height: 0.3rem;
				}
			}
		}
	}

	hr {
		width: 100%;
		background: linear-gradient(
			to right,
			rgba(255, 255, 255, 0),
			rgba(255, 255, 255, 1),
			rgba(255, 255, 255, 0)
		);
		opacity: 0.5;
		height: 0.01rem;
		border: none;
    margin: 0.13rem 0;
	}

	.price {
		display: flex;
		gap: 7%;

		span {
			font-family: RobotoBlack;
			font-size: 0.23rem;
		}
		img {
			max-height: 0.26rem;
			vertical-align: middle;
		}
	}

	.btn-purchase {
		background: url(${cdn}btn-yes.png) center/contain no-repeat;
		padding: 2% 10%;
		cursor: pointer;
    display: flex;
		span {
			font-size: 0.23rem;
			font-family: RobotoMedium;
		}
	}
`
