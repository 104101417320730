const main_state = {
	login: false,
	loader: false,
	loader_hash: false,
	lang_json: false,
	popup_type: false,
	selected_period: 0,
	time: false,
	items: false,
	history: false,
	selected: false,
	result: false,
	amount: 1,
	is_showed: false,
	is_bgm_play: false,
	loading_purchase: false,
}

const main = (state = main_state, action) => {
	switch (action.type) {
		case "PUT_DATA":
			return { ...state, [action.key]: action.data }
		case "TOGGLE_LOADER":
			return { ...state, loader: action.data }
		case "TOGGLE_LOADER_HASH":
			return { ...state, loader_hash: action.data }
		case "TOGGLE_POPUP":
			return { ...state, popup_type: action.tipe }
		default:
			return state
	}
}

export default main
