import React, { lazy, Suspense, useEffect } from "react"
import { Switch, BrowserRouter as Router, Route } from "react-router-dom"

// Component
import ErrorBoundary from "ErrorBoundary"
import ViewportRestrict from "helper/component/viewport"
import Loader from "helper/component/loader"
import NotFound from "helper/component/404"
import LoadingScreen from "helper/component/loadingscreen"
import { componentLoader } from "helper"

// REDUX
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as mainActions from "redux/actions/main"
import ModalCont from "components/Modal"

// Pages
const Home = lazy(() => componentLoader(() => import("pages/Home")))

export const Main = (props) => {
	const { set_token, get_translate } = props.actionsMain
	useEffect(() => {
		get_translate("EN")
		set_token()
	}, []) // eslint-disable-line

	return ( 
		<Router basename={process.env.PUBLIC_URL}>
			<div id="Main" className="main-panel">
				<ErrorBoundary>
					<Suspense fallback={<LoadingScreen />}>
						<ViewportRestrict display={false} type="portrait" />
						<Loader loader={props?.main?.loader} />
						<div className="content-container">
							<Switch>
								<Route exact path="/" render={() => <Home {...props} />} />
								<Route component={NotFound} />
							</Switch>
						</div>
					</Suspense>
				</ErrorBoundary>
				<ModalCont {...props} />
			</div>
		</Router>
	)
}

const mapStateToProps = (state) => ({
	main: state.main,
})

const mapDispatchToProps = (dispatch) => ({
	actionsMain: bindActionCreators(mainActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Main)
