import axios from "axios"
import { err_msg, getLsObject, getUrlParam, removeParamUrl } from "helper"
// import { set_hash } from './auth';
// import { put_hash } from './hash';

const ls = window.localStorage
const cdn = axios.create({
	baseURL: `https://cdngarenanow-a.akamaihd.net/devid/${process.env.REACT_APP_LOCALIZE_NAME}/`,
	withCredentials: false,
})

const common = axios.create({
	baseURL: `https://cdngarenanow-a.akamaihd.net/webid/TranslateCommon/`,
	withCredentials: false,
})

export const toggle_popup = (tipe) => ({
	type: "TOGGLE_POPUP",
	tipe,
})

export const toggle_loader = (data) => ({
	type: "TOGGLE_LOADER",
	data: data,
})

export const toggle_loader_hash = (data) => ({
	type: "TOGGLE_LOADER_HASH",
	data: data,
})

export const put_data = (key, data) => ({
	type: "PUT_DATA",
	key,
	data,
})

/**
 *
 * Example of how to call API
 *
 */
export const set_token = () => {
	return (dispatch) => {
		var token = getUrlParam("token")
		// dispatch(toggle_loader(true))

		const loginErrorMapping = {
			m: "Maintenance",
			1: "Error fetching Garena user data, please try close and reopen banner",
			2: "Error fetching Garena user data, please try close and reopen banner",
			3: "Missing CODM data, please try again with another account",
			4: "Missing CODM data, please try again with another account",
			5: "Account must have level 2 or higher to participate this event",
			41: "Please logout your account from the game and login again to enter the Event"
		  }  

		if (getUrlParam("err")) {
			let error = getUrlParam("err")
			
			dispatch(catch_error(loginErrorMapping[error]))
			removeParamUrl()
		} else {
			if (token) {
				ls.setItem("token", token)
				window.location.href = process.env.REACT_APP_BASE_URL
			} else {
				dispatch(login())
			}
		}
	}
}

export const login = () => {
	return (dispatch, getState) => {
		// UNCOMMENT & ADD DEFAULT LANG AS PARAM ex: 'EN'
		// dispatch(get_translate('EN'))
		dispatch(toggle_loader(true))

		axios
			.get("oauth/check_login/")
			.then((resp) => {
				dispatch(put_data("login", resp.data))
				dispatch(get_time())
				dispatch(get_history())

				/*
					If you want to set the data as HASH, 
					replace the key below with a hash key and 
					don't forget to include the state key into 
					whitelist in reducers/index.js
				*/
				// const key = 'login';
				// const { temp_hash } = getState().hash;
				// if (temp_hash[key].type === 'set_hash') {
				//   dispatch(set_hash(key));
				// } else {
				//   dispatch(put_hash(key, temp_hash[key].data));
				// }
			})
			.catch((err) => {
				dispatch(catch_error(err_msg(err)))
			})
			.then(() => {
				dispatch(toggle_loader(false))
			})
	}
}

export const get_time = () => {
	return (dispatch) => {
		axios.get("api/sale/time/").then((resp) => {
			dispatch(put_data("time", resp.data))
			const { sale_time } = resp.data

			let active_period =
				sale_time?.find((period) => period.status === 1) ||
				sale_time?.find((period) => period.status === 0) ||
				sale_time?.find((period) => period.status === 2)

			if (resp.data.sale_time.length > 0) {
				dispatch(get_sale_items(active_period?.id))
				dispatch(put_data("is_showed", active_period?.is_showed))
			}
		})
	}
}

export const get_sale_items = (id) => {
	return (dispatch) => {
		axios.get(`api/sale/${id}/`).then((resp) => {
			dispatch(put_data("items", resp.data))
			dispatch(put_data("active_period", id))
		})
	}
}

export const get_history = () => {
	return (dispatch) => {
		axios.get("api/history/").then((resp) => {
			dispatch(put_data("history", resp.data))
		})
	}
}

export const post_purchase = () => {
	return (dispatch, getState) => {
		const { active_period, selected, amount } = getState().main
		dispatch(put_data("loading_purchase", true))

		axios
			.post("api/purchase/", {
				sale_time_id: active_period,
				item_id: selected.id,
				cnt: amount,
			})
			.then((resp) => {
				dispatch(login())
				dispatch(put_data("result", resp.data))
				dispatch(toggle_popup("Success"))
			})
			.catch((err) => {
				dispatch(catch_error(err_msg(err)))
			})
			.then(() => {
				dispatch(put_data("amount", 1))
				dispatch(put_data("loading_purchase", false))
			})
	}
}

/**
 *
 * Example of how to implement multi language
 *
 */
export const get_translate = (default_lang) => {
	return (dispatch) => {
		let language = getLsObject("param")?.lang.toUpperCase() || default_lang

		const main_localize = cdn.get(`data.json?${Math.random() * 10000}`)
		const common_localize = common.get(
			`${language}.json?${Math.floor(Math.random() * 10000)}`
		)

		axios
			.all([main_localize, common_localize])
			.then((resp) => {
				const json = {
					...resp[0].data.data,
					...resp[1].data,
				}
				dispatch(put_data("lang_json", json))
			})
			.catch((err) => {})
	}
}

/**
 *
 * Example of how to catch error if not logged in
 *
 */
export const catch_error = (err_msg) => {
	return (dispatch, getState) => {
		// if (err_msg === "not_logged_in") {
		// const { auth } = getState()
		// window.location.href = `${`${process.env.REACT_APP_BASE_URL}?access_token=${auth.param.access_token}&account_id=${auth.param.account_id}&nickname=${auth.param.nickname}&region=${auth.param.region}`}`
		// } else {
		if (err_msg) {
			dispatch(put_data("err_msg", err_msg))
		}
		dispatch(toggle_popup("Error"))
		// }

		if (err_msg === "invalid_region") {
			localStorage.clear()
		}
	}
}
