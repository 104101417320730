import React, { useEffect } from "react"
import styled from "styled-components"
import useSound from "use-sound"

const cdn = process.env.REACT_APP_CDN_URL

const Success = (props) => {
	const { toggle_popup } = props.actionsMain
	const { result } = props.main

	// SFX
	const [playPurchased] = useSound(`${cdn}sfx/purchased.mp3`)
	const [playOpen] = useSound(`${cdn}sfx/open.mp3`)
	useEffect(() => {
		playPurchased()
	})

	const closeModal = () => {
		toggle_popup(false)
		playOpen()
	}

	return (
		<Wrapper>
			<div className="image">
				<img src={result.image_icon} alt="" />
			</div>
			<span>
				<span>{result.item_name}</span> sent to ingame mail
			</span>
			<div className="btn-ok" onClick={() => closeModal()}>
				<span>Ok</span>
			</div>
		</Wrapper>
	)
}

export default Success

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	height: 88%;
	padding-bottom: 12%;
	font-family: RobotoMedium;

	.image {
		height: 35%;
		width: 16.7%;

		> img {
			max-height: 75%;
			max-width: 75%;
		}
	}

	> span {
		color: #ffffff;
		font-size: 0.26rem;
		margin: 5% 0;

		> span {
			font-family: RobotoBlack;
		}
	}

	.btn-ok {
		background: url(${cdn}btn-yes.png) center/contain no-repeat;
		color: #ffffff;
		width: 28%;
		height: 18%;
		display: grid;
		place-items: center;
		font-size: 0.23rem;
	}
`
