import React, { useEffect } from "react"
import styled from "styled-components"
import useSound from "use-sound"

const cdn = process.env.REACT_APP_CDN_URL

const Error = (props) => {
	const { err_msg, lang_json } = props.main
	// SFX
	const [playOpen] = useSound(`${cdn}sfx/open.mp3`)
	useEffect(() => {
		playOpen()
	})

	return (
		<Wrapper>
			<span>
				{lang_json[err_msg] ||
					err_msg ||
					"Something went wrong, try again later"}
			</span>
		</Wrapper>
	)
}

export default Error

const Wrapper = styled.div`
	height: 88%;
	display: grid;
	place-items: center;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 0.33rem;
	span {
		color: #fff;
		font-family: RobotoMedium;
		font-size: 0.26rem;
		text-align: center;
	}
`
