import React, { useEffect, useState } from "react"
import styled from "styled-components"
import useSound from "use-sound"

const cdn = process.env.REACT_APP_CDN_URL

const Confirmation = (props) => {
	const { post_purchase } = props.actionsMain
	const { selected, amount } = props.main

	const [checkbox, setCheckbox] = useState(
		window.localStorage.getItem("dontshow") || false
	)

	// SFX
	const [playOpen] = useSound(`${cdn}sfx/open.mp3`)
	useEffect(() => {
		playOpen()
	})

	const purchase = () => {
		window.localStorage.setItem("dontshow", checkbox)
		post_purchase()
	}

	return (
		<Wrapper>
			<span>
				Spend {selected.final_price * amount}{" "}
				<img src={`${cdn}voucher.png`} alt="" /> for {selected.item_name}?
			</span>
			<div className="dontshow">
				<input
					type="checkbox"
					name="dontshow"
					id="dontshow"
					checked={checkbox}
					onChange={() => setCheckbox(!checkbox)}
				/>
				<span>Don't show this message again</span>
			</div>
			<div className="btn-ok" onClick={() => purchase()}>
				<span>Ok</span>
			</div>
		</Wrapper>
	)
}

export default Confirmation

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	height: 88%;
	padding-bottom: 12%;
	font-family: RobotoMedium;

	> span {
		color: #fff8a9;
		font-size: 0.26rem;
		padding: 0 15%;
		text-align: center;

		> img {
			height: 0.33rem;
			vertical-align: middle;
		}

		> span {
			font-family: RobotoBlack;
		}
	}

	.dontshow {
		color: #ffffff;
		font-size: 0.2rem;
		margin: 5% 0;

		input {
			vertical-align: middle;
		}
		span {
			vertical-align: middle;
		}
	}

	.btn-ok {
		background: url(${cdn}btn-yes.png) center/contain no-repeat;
		color: #ffffff;
		width: 28%;
		height: 18%;
		display: grid;
		place-items: center;
		font-size: 0.23rem;
		cursor: pointer;
	}
`
